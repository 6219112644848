<template>
  <div class="checkDetail">
    <device-share-status></device-share-status>
  </div>
</template>

<script>
import DeviceShareStatus from '@/views/shareAndDispatch/device/deviceShareDetail/deviceStatus/deviceShareStatus'

export default {
  name: 'checkDetail',
  components: { DeviceShareStatus }
}
</script>

<style lang="scss" scoped>

.checkDetail {
  width: 100%;
  height: calc(100% - 40px);
}

</style>
